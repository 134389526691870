import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  FormHelperText,
  Box,
  Button,
  Collapse,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "../../../scss/catalog.scss";
import EditorConvertToHTML from "../../../components/RichText";
import CategoryManages from "../../../api/categoryManages";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValDataArticle,
  initValDataArticleRules,
  initValFilters,
  valSchDataArticle,
  valSchDataArticleRules,
  valSchFilters,
} from "../../../utils/formHelper";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import CountWords from "../../../components/CountWords";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import {
  GenericHeaderSubTitle,
  GenericHeaderTitle,
} from "../../../components/GenericHeader";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import CircleIcon from "@mui/icons-material/Circle";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { ModalRight } from "../../../components/GenericsModals";
import { Filters, ProductsFilters } from "../../../api/catalog/filterManage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { South } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const ArticleData = ({ article, getArticleInfo, load }) => {
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  const SetProductInfo = (formData, setLoading) => {
    (async () => {
      let setProduct = new Products();
      let response = await setProduct.editDataAuth(
        article.id,
        formData,
        logout
      );
      if (response) {
        sendNotification(UseerrorOrsuccess(response));
      } else {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      }
      getArticleInfo();
      setLoading(false);
    })();
  };

  return (
    <div>
      <Grid container mb={"32px"}>
        <ArticleInfo
          article={article}
          SetProductInfo={SetProductInfo}
          load={load}
        />
        <FiltersFeatures
          article={article}
          SetProductInfo={SetProductInfo}
          load={load}
          getArticleInfo={getArticleInfo}
        />
        <ArticleRules
          article={article}
          SetProductInfo={SetProductInfo}
          load={load}
        />
      </Grid>
    </div>
  );
};

const ArticleInfo = ({ article, SetProductInfo, load }) => {
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();

  /*   const getCategory = () => {
    (async () => {
      let getCategory = new CategoryManages();
      let response = await getCategory.getAllAuth(logout);
      setCategory(response.data);
    })();
  }; */

  const handleOnClickDescription = (text) => {
    setDescription(text);
    /*   let des =article.description   TODO: FORMIK.DIRT
    if(formik.values.description !== text){
      console.log(  text.toString() ===  des.toString()  )
      console.log(text )
      console.log(article.description )
      console.log("entre alk formik")
      formik.setFieldValue("description",text) 
    } */
    //formik.setFieldValue("description",text)
  };

  const formik = useFormik({
    initialValues: initValDataArticle(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDataArticle()),
    onSubmit: async (formData) => {
      setLoading(true);
      formik.values.description = description;
      console.log(formData);
      SetProductInfo(formData, setLoading);
    },
  });
  useEffect(() => {
    //getCategory();
    window.localStorage.setItem("menu", 10);
  }, []);

  useEffect(() => {
    if (article?.description) {
      setDescription(article.description);
    } else if (article?.description === null) {
      setDescription("");
    }

    if (article) {
      formik.resetForm();
    }
  }, [article]);

  return (
    <>
      <Grid item xs={12} mb={"32px"}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={"8px"} columnSpacing={"8px"}>
            <Grid item xs={12}>
              <GenericHeaderTitle
                title={
                  <>
                    {" "}
                    {formik.values.is_active ? (
                      <CircleIcon sx={{ color: "green", fontSize: 15 }} />
                    ) : (
                      <CircleIcon sx={{ color: "red", fontSize: 15 }} />
                    )}{" "}
                    Datos del artículo{" "}
                  </>
                }
              >
                {article && (
                  <FormControlLabel
                    control={
                      <Switch
                        name="is_active"
                        checked={formik.values.is_active}
                        onChange={formik.handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={formik.values.is_active ? "Desactivar" : "Activar"}
                  />
                )}
              </GenericHeaderTitle>
            </Grid>
            {article && (
              <>
                <Grid item xs={12} sm={6} mt={"8px"}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    label="Nombre"
                    size="small"
                    helperText={formik.touched.name ? formik.errors.name : ""}
                  />

                  <CountWords length={formik.values.name.length} max="100" />
                </Grid>
                <Grid item xs={12} sm={6} mt={"8px"}>
                  <TextField
                    fullWidth
                    id="sku"
                    name="sku"
                    value={formik.values.sku}
                    onChange={formik.handleChange}
                    error={formik.touched.sku && Boolean(formik.errors.sku)}
                    label="SKU"
                    size="small"
                    helperText={formik.touched.sku ? formik.errors.sku : ""}
                  />
                  <CountWords length={formik.values.sku.length} max="20" />
                </Grid>

                <Grid item xs={12} mb={"16px"}>
                  <Typography variant="h6" gutterBottom component="div">
                    Descripción
                  </Typography>
                  <Paper sx={{ backgroundColor: "#fff", padding: '6px 10px 0' }}>
                    {description !== null && (
                      <EditorConvertToHTML
                        handleClick={handleOnClickDescription}
                        data={description}
                      />
                    )}
                  </Paper>
                </Grid>
                {/*  <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-filled-label">
                      Tipo de artículo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      name="type"
                      label="Tipo de artículo"
                      value={formik.values.type || ""}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                    >
                      {/*             <MenuItem value="">
                  <em>none</em>
                </MenuItem> //
                      <MenuItem value={1}>Almacenable</MenuItem>
                      <MenuItem value={2}>Servicio</MenuItem>
                      <MenuItem value={3}>Consumible</MenuItem>
                    </Select>
                    <FormHelperText>
                      {formik.touched.type ? formik.errors.type : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  {category && (
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="demo-simple-select-filled-label">
                        Asignar Categoría
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        name="category"
                        label="Asignar Categoría"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.category &&
                          Boolean(formik.errors.category)
                        }
                      >
                        {/*         <MenuItem value="">
                        <em>Categorías</em>
                      </MenuItem> 
                        {category.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.category ? formik.errors.category : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid> */}
              </>
            )}

            {!article && load && (
              <Grid container justifyContent="center" p={"32px"}>
                <CircularProgress />
                {/* <SkeletonLoading /> */}
              </Grid>
            )}
            {article && (
              <Grid container justifyContent="flex-end" mt={"16px"}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  /* disabled={!formik.dirty} */
                  >
                    Actualizar
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form >
      </Grid >
    </>
  );
};

const ArticleRules = ({ article, SetProductInfo, load }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initValDataArticleRules(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDataArticleRules()),
    onSubmit: async (formData) => {
      setLoading(true);
      if (formData.discount === "") {
        formData.discount = null;
      }
      console.log(formData);
      SetProductInfo(formData, setLoading);
    },
  });

  return (
    <>
      <Grid item xs={12} mt={"8px"} mb={"8px"}>
        <GenericHeaderSubTitle title="Reglas del artículo" />
      </Grid >
      {article && (
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={"12px"}>
              <Grid item xs={12} sm={6} mt={"8px"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_preparation_time"
                      checked={formik.values.is_preparation_time}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.is_preparation_time
                          ? formik.errors.is_preparation_time
                          : ""
                      }
                    />
                  }
                  label="Tiempo de preparación"
                />
                <FormHelperText>
                  {formik.touched.is_preparation_time
                    ? formik.errors.is_preparation_time
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} mt={"8px"}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Días
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="preparation_time"
                    value={formik.values.preparation_time || ""}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.preparation_time &&
                      Boolean(formik.errors.preparation_time)
                    }
                    /* autoWidth */
                    MenuProps={MenuProps}
                    label="Días"
                  >
                    <MenuItem value="">
                      <em>Días</em>
                    </MenuItem>
                    <MenuItem value={1}>{`1 día`}</MenuItem>
                    {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (number) => (
                        <MenuItem
                          key={number}
                          value={number}
                        >{`${number} días`}</MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText>
                    {formik.touched.preparation_time
                      ? formik.errors.preparation_time
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Mostrarse en:</Typography>

                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                  }} /* justifyContent="space-evenly" */
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_store"
                        checked={formik.values.display_on_store}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_store
                            ? formik.errors.display_on_store
                            : ""
                        }
                      />
                    }
                    label="Tienda en línea"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_pos"
                        checked={formik.values.display_on_pos}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_pos
                            ? formik.errors.display_on_pos
                            : ""
                        }
                      />
                    }
                    label="Punto de venta"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="display_on_catalog"
                        checked={formik.values.display_on_catalog}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.display_on_catalog
                            ? formik.errors.display_on_catalog
                            : ""
                        }
                      />
                    }
                    label="Catálogo"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  Descuento:{" "}
                  <OutlinedInput
                    id="discount"
                    name="discount"
                    value={formik.values.discount || ""}
                    onChange={formik.handleChange}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                    error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                    }
                    /* label="%" */
                    size="small"
                    type="number"
                    helperText={
                      formik.touched.discount ? formik.errors.discount : ""
                    }
                  />
                </Typography>
                <Typography variant="body1" sx={{ margin: "16px 0" }}>
                  El descuento aplica en las siguientes aplicaciones:
                </Typography>

                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                  }} /* justifyContent="space-evenly" */
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="discount_store"
                        checked={formik.values.discount_store}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.discount_store
                            ? formik.errors.discount_store
                            : ""
                        }
                      />
                    }
                    label="Tienda en línea"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="discount_pos"
                        checked={formik.values.discount_pos}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.discount_pos
                            ? formik.errors.discount_pos
                            : ""
                        }
                      />
                    }
                    label="Punto de venta"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" mt={"16px"}>
                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      Actualizar
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
      {!article && load && (
        <Grid container justifyContent="center" p={"32px"}>
          <CircularProgress />
          {/* <SkeletonLoading /> */}
        </Grid>
      )}
    </>
  );
};

const SkeletonLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
      <Skeleton variant="text" sx={{ fontSize: "2rem", width: "50%" }} />
      <Skeleton variant="text" sx={{ fontSize: "2rem", width: "49%" }} />
      <Skeleton variant="rectangular" sx={{ width: "100%" }} height={90} />
      <Skeleton variant="text" sx={{ fontSize: "2rem", width: "50%" }} />
      <Skeleton variant="text" sx={{ fontSize: "2rem", width: "49%" }} />
      {/*  <Skeleton variant="rounded" width={210} height={60} /> */}
    </Box>
  );
};

const FiltersFeatures = ({ article, SetProductInfo, load, getArticleInfo }) => {
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  const getCategory = () => {
    (async () => {
      let getCategory = new CategoryManages();
      let response = await getCategory.getAllAuth(logout);
      setCategory(response.data);
    })();
  };

  useEffect(() => {
    getCategory();
  }, []);

  const formik = useFormik({
    initialValues: initValFilters(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchFilters()),
    onSubmit: async (formData) => {
      setLoading(true);

      console.log(formData);
      SetProductInfo(formData, setLoading);
    },
  });

  return (
    <>
      <Grid container>
        {open && <ModalFilter open={open} setOpen={setOpen} dataFilters={article.filters} update={getArticleInfo} />}

        <Grid item xs={12} mt={"8px"} mb={"8px"}>
          <GenericHeaderSubTitle title="Filtros y características" />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={"8px"}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="demo-simple-select-filled-label">
                    Tipo de artículo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    name="type"
                    label="Tipo de artículo"
                    value={formik.values.type || ""}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                  >
                    {/*             <MenuItem value="">
                  <em>none</em>
                </MenuItem> */}
                    <MenuItem value={1}>Almacenable</MenuItem>
                    <MenuItem value={2}>Servicio</MenuItem>
                    <MenuItem value={3}>Consumible</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.type ? formik.errors.type : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {category && (
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-filled-label">
                      Asignar Categoría
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      name="category"
                      label="Asignar Categoría"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.category &&
                        Boolean(formik.errors.category)
                      }
                    >
                      {/*         <MenuItem value="">
                        <em>Categorías</em>
                      </MenuItem> */}
                      {category.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.category ? formik.errors.category : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={10} sm={5}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    <strong>Filtrar por: </strong>
                  </Typography>
                  <Button
                    onClick={() => setOpen(true)}
                    sx={{ color: "primary.main" }}
                    startIcon={<AddIcon />}
                  >
                    Asignar
                  </Button>
                </Box>

                {/*         <AddIcon
                      sx={{color: "primary.main"}}
                      
                    /> */}

                <Stack direction="column">
                  {article.filters &&
                    article.filters.map((row, index) => (
                      <FilterList
                        row={row}
                        key={index}
                        index={index}
                        update={getArticleInfo}
                        setOpen={setOpen}
                      />
                    ))}
                </Stack>
              </Grid>

              <Grid container justifyContent="flex-end" mt={"16px"}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    type="submit"
                    variant="contained"
                  /* disabled={!formik.dirty} */
                  >
                    Actualizar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

const FilterList = ({ row, index, update, setOpen }) => {
  const [msg, sendNotification] = useNotification();

  const { logout } = useAuth();



  /*   const deleteLabel = async (id) => {
    
      let setLabel = new ProductsFilters();
      const response = await setLabel.deleteAuth(id, logout);
      sendNotification(UseerrorOrsuccess(response));
      update();
      
    }; */

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: "8px",
          margin: "8px 0px 0px 0px",
        }}
        key={index}
      >
        <div>
          <strong>{` ${row.filter} `} </strong>
        </div>
        <div>

          <IconButton
            sx={{

              padding: 0,
              color: "inherit",
              margin: "0 12px 0 32px",
            }}
            onClick={() => {

              setOpen(true)
            }}
          >
            <EditIcon sx={{ cursor: "pointer" }} />
          </IconButton></div>
      </Box>

      <Stack direction="column">
        {row.labels &&
          row.labels.map((label, index) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "8px 8px 8px 16px",
                margin: "0px 0px",
              }}
              key={index}
            >
              <div>{`${label.name} `}</div>

              {/*              <CloseIcon
                onClick={() => { 
                  deleteLabel(label.product_filter_id);
             }}
                sx={{ cursor: "pointer" }}
              />  */}
            </Box>
          ))}
      </Stack>
      {/*    <Divider sx={{ height: '1px', borderColor: "text.primary", width: "90%", margin: "auto", backgroundColor: "text.primary", }} /> */}
    </>
  );
};

const ModalFilter = ({ open, setOpen, dataFilters, update }) => {
  const [filters, setFilters] = useState("");
  const { logout } = useAuth();


  const getFilter = () => {
    (async () => {
      let getCategory = new Filters();  /* Filters product_filter */
      let response = await getCategory.getAllAuth(logout);
      setFilters(response.data);
    })();
  };


  useEffect(() => {
    getFilter();
  }, [open]);

  return (
    <ModalRight setOpen={setOpen} open={open} title="Filtros" size="xs">
      {filters &&
        filters.map((row, index) => <LabelList row={row} key={index} dataFilters={dataFilters} update={update} />)}
    </ModalRight>
  );
};

const LabelList = ({ row, dataFilters, update }) => {
  const [arrow, setArrow] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {

    if (dataFilters && dataFilters.find((x) => x.filter === row.name)) {
      setData(dataFilters.filter((x) => x.filter === row.name))
    }
  }, [row, dataFilters])

  return (
    <Box>
      <Box sx={{ padding: "8px 16px" }}>
        <Grid container>
          <Grid item xs={9} sm={6}>
            <Grid container justifyContent="start" alignItems="center">
              <Typography>
                <strong>{row.name}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={6}>
            <Grid
              container
              justifyContent="end"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <IconButton
                sx={{ margin: 0, padding: 0 }}
                onClick={() => {
                  setArrow(!arrow);
                }}
              >
                {arrow ? (
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{ color: "text.primary", margin: 0, padding: 0 }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={arrow} timeout={1000} unmountOnExit>
        <Grid item xs={12} sx={{ padding: "16px 16px" }}>
          <Stack direction="column">
            {row?.labels &&
              row?.labels.map((label, index) => (
                <LabelCheck key={index} idFilter={row.id} label={label} index={index} dataFilters={data} update={update} />
              ))}
          </Stack>
        </Grid>
      </Collapse>
    </Box>
  );
};

const LabelCheck = ({ label, idFilter, index, dataFilters, update }) => {
  const [isCheck, setIsCheck] = useState(false);
  const { id } = useParams()
  const { logout } = useAuth();
  const [data, setData] = useState("")
  const [msg, sendNotification] = useNotification();

  useEffect(() => {


    if (dataFilters[0]?.labels.find((x) => x.id === label.id)) {
      console.log(dataFilters[0]?.labels.find((x) => x.id === label.id))
      setData(dataFilters[0]?.labels.find((x) => x.id === label.id))
      setIsCheck(true)
    }

  }, [dataFilters, label])

  const addLabel = async (idLabel) => {
    const formData = {
      product: id,
      filter: idFilter,
      label: idLabel
    }

    let setUser = new ProductsFilters();
    let response = await setUser.postDataAuth(formData, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();
  }

  const deleteLabel = async (id) => {

    let setLabel = new ProductsFilters();
    const response = await setLabel.deleteAuth(id, logout);
    sendNotification(UseerrorOrsuccess(response));
    update();

  };
  const changesCheck = (id) => {
    setIsCheck(!isCheck);

    if (!isCheck) {
      addLabel(id)
      console.log("post " + !isCheck)
    } else {
      deleteLabel(data.product_filter_id)
      console.log("delete  " + !isCheck)
    }


  };


  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "8px 8px 8px 16px",
        margin: "0px 0px",
      }}
      key={index}
    >
      <div>{`${label.name} `}</div>

      <FormControlLabel
        control={
          <Checkbox
            name="label"
            checked={isCheck}
            onChange={() => changesCheck(label.id)}
          />
        }
      />
    </Box>
  );
};

export default ArticleData;
