import React, {useEffect, useState} from "react";
import "../../scss/adminBusiness.scss";
import Container from "../../layout/gridContainer";
import Profile from './business/profile';
import TaxInformation from './business/taxInformation';
import BusinessManages from '../../api/businessManages';
import { useAuth } from "user-context-fran-dev";
import GenericAddress from "../../components/GenericAddress";

function MyBusiness() {
  const [business, setBusiness] = useState("");
  const { logout } = useAuth();

  useEffect(() => {
    UpdateBusinessInfo();
  }, []);

  const UpdateBusinessInfo = () => {
    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllAuth(logout);
      setBusiness(response.data);
    })();
  };
  
  return (
    <div>
      <Container>
        {business &&
        <>
          <Profile data={business} UpdateInfo={UpdateBusinessInfo}/>
          <GenericAddress data={business} UpdateInfo={UpdateBusinessInfo} target={'business'}/>
          {/* <Address data={business} UpdateInfo={UpdateBusinessInfo}/> */}
          <TaxInformation data={business} UpdateInfo={UpdateBusinessInfo}/>
          {/* <SocialMedia data={business} UpdateInfo={UpdateBusinessInfo}/> */} 
        </>
        }
      </Container>
    </div>
  );
}

export default MyBusiness;
