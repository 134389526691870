import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid2 from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';


export default function MediaControlCard({orderData}) {
  const theme = useTheme();
  const navigate = useNavigate();

  console.log('orderdata', orderData)

  const gotToPay = () =>{
    navigate(`/pos/pointofsale/payment/sale/`+orderData['0'].id)
  }

  return (
    <Grid2 container direction="column" sx={{bottom: 0}}>
        
        <Grid2 xs textAlign={'center'}>
            <Grid2 sx={{textAlign: 'center'}}>
              <Button variant="contained" sx={{height: 90, marginBottom:'10px', width:'95%', borderRadius: '20px'}} disabled={orderData['0'].articles_total == 0} 
                className='pay-button' onClick={gotToPay}>
                  <Grid2 container>
                  <Grid2 xs={12} sx={{textAlign: 'center'}}>
                    <h2>Pagar:</h2>
                  </Grid2>
                  <Grid2 xs={12} sx={{textAlign: 'center'}}>
                    <h2><CurrencyFormat value={orderData['0'].subtotal} displayType={'text'} thousandSeparator={true} prefix={'$'}/></h2>
                  </Grid2>
                  </Grid2>
              </Button>
            </Grid2>
        </Grid2>
      </Grid2>
  );
}
