import React, {useState} from "react";
import { Paper, Typography } from "@mui/material";
import { TextField, Grid } from "@mui/material";
import "../../../scss/adminBusiness.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { initValBusinessTax, valSchBusinessTax } from "../../../utils/formHelper";
import { useAuth } from "user-context-fran-dev";
import BusinessManages from '../../../api/businessManages';
import { UseerrorOrsuccess, useNotification } from '../../../hooks/snackBarResponse'

const TaxInformation =({data, UpdateInfo})=>{
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const [modified , setModified] = useState(false);

  const UpdateTaxt = async (id, formData) => {
      let updateTaxt = new BusinessManages();
      const response = await updateTaxt.editDataAuth(id, formData, logout);
      sendNotification(UseerrorOrsuccess(response))
      UpdateInfo();
  }

  const formik = useFormik({
    initialValues: initValBusinessTax(data),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchBusinessTax()),
    onSubmit: async (formData) => {
        console.log(formData)
       
      setLoading(true);
      UpdateTaxt(data.id, formData)
      setLoading(false);
    },
  });

    return(
  
      <Paper className="paper">
        <Typography variant="h6" gutterBottom component="div">
          <strong>Información fiscal</strong>
        </Typography>
        <form  onSubmit={formik.handleSubmit} onChange={()=> setModified(true)}>
          <Grid container>
          
            <Grid item xs={12}>
              <TextField
                   fullWidth
                   name="business_name"
                   label="Razón social"
                   margin="dense"
                   size="small"
                   value={formik.values.business_name}
                   onChange={formik.handleChange}
                   error={
                       formik.touched.business_name && Boolean(formik.errors.business_name)
                   }
                
                   helperText={
                       formik.touched.business_name ? formik.errors.business_name : ""
                   }
              />
              <TextField
                fullWidth
                name="rfc"
                label="RFC"
                margin="dense"
                size="small"
                value={formik.values.rfc}
                onChange={formik.handleChange}
                error={
                    formik.touched.rfc && Boolean(formik.errors.rfc)
                }
            
                helperText={
                    formik.touched.rfc ? formik.errors.rfc : ""
                }
              />
            </Grid>
          <Grid  item xs={0} sm={8} md={9} lg={10}/>
          <Grid  item xs={12} sm={4} md={3} lg={2} >
              <br/>
              <LoadingButton
                  fullWidth
                  className='button'
                  type="submit"
                  loading={loading} 
                  disabled={!modified}
                  variant="contained"
              >
                  Actualizar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
  
    );
  }
 
export default TaxInformation;